import React, { FunctionComponent } from 'react';
import ContactUs from 'src/shared/components/contact/contact.component';
import { Image } from 'semantic-ui-react';
import webStyles from './recruit.module.scss';
import mobileStyles from './recruit-mobile.module.scss';

import useMobile from 'src/shared/hooks/use-mobile';
import useWindowDimensions from 'src/shared/hooks/use-window-dimensions';
import VideoComponent from 'src/shared/components/videoComponent/videoComponent';
import portalMain from '../../assets/images/college/portal-main.png';
import mobilePortalMain from '../../assets/images/college/portal-mobile.png';
import mobilePortalPos from '../../assets/images/college/portal-mobile-pos.png';
import rosterManagementMain from '../../assets/images/college/roster-management.png';
import header from '../../assets/images/textures/playcard-texture.png';

const Recruit: FunctionComponent = () => {
    const mobile = useMobile();
    const styles = mobile ? mobileStyles : webStyles;
    const { width } = useWindowDimensions();

    return (
        <div className={styles.container}>
            <div className={styles.headline}>
                <Image src={header} />
                <h2>Take Your Recruiting to the Next Level.</h2>
                <h2>All-in-One Platform for AI-Driven Evaluation Tools.</h2>
            </div>
            <div className={styles.transferPortal}>
                <div className={styles.transferPortalContent}>
                    <h2>TRANSFER PORTAL</h2>
                    <div className={styles.transferPortalContentContainer}>
                        {mobile ? (
                            <div className={styles.mobileHeaders}>
                                <Image src={mobilePortalMain} className={styles.mainImg} />
                                <Image src={mobilePortalPos} className={styles.mainImg} />
                            </div>
                        ) : null}
                        <div className={styles.transferPortalText}>
                            <p>
                                Understanding, navigating and analyzing data within the transfer portal can be a
                                time-consuming process for you and your staff. Our goal at Telemetry sports is for
                                programs to quickly identify roster fits by providing unique data that NFL staff&apos;s
                                have been using to evaluate since 2019.
                            </p>
                            <p>
                                Telemetry Sports makes player information simple to access: Discovering key players from
                                the portal has never been easier. Each player&apos;s unique profile and career data,
                                linked to film, provides top key stats and proprietary evaluation metrics based on
                                highlights of the player&apos;s top career speeds and career alignment on the field.
                            </p>
                            <p>
                                Being in the know when the transfer portal opens is crucial. With Telemetry Sports
                                portal product, programs can specify player parameters through the website, notifying
                                them when a player that checks all their boxes enters the portal. Trusted information is
                                power. Quick access to our data can help your team win in the margins and reduce your
                                misses to continue to improve your roster through the Portal.
                            </p>
                        </div>
                        {!mobile ? (
                            <div style={{ display: 'flex', flex: 1.3, alignItems: 'center', marginTop: -30 }}>
                                <Image src={portalMain} className={styles.mainImg} />
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
            <div className={styles.rosterManagement}>
                <div className={styles.rosterManagementContentContainer}>
                    {!mobile ? (
                        <div style={{ display: 'flex', flex: 1.3, alignItems: 'center', marginTop: -30 }}>
                            <Image src={rosterManagementMain} className={styles.rmMainImg} />
                        </div>
                    ) : null}

                    <div className={styles.rosterManagementText}>
                        <h2>ROSTER MANAGEMENT</h2>
                        {mobile ? (
                            <div className={styles.mobileHeaders}>
                                <Image src={rosterManagementMain} className={styles.rmMainImg} />
                            </div>
                        ) : null}
                        <p>
                            In the NIL era of college football, we understand the complexities of recruiting and
                            managing a roster have expanded significantly. Beyond evaluating athletic and academic
                            potential, programs must now consider NIL opportunities and their ability to navigate this
                            evolving landscape. Effective roster management now requires not only traditional skills but
                            also a forward-thinking approach to partnerships, branding, and communication to ensure
                            long-term program success.
                        </p>
                        <p>
                            Telemetry Sports Roster Management Tool makes it easy to manage your current and future
                            rosters and keep track of your spending. Create custom lists, move players around on your
                            board, and create custom views - we give you the tools to do what you need as efficiently as
                            possible.
                        </p>
                        <p>
                            Trusted by 25 NFL clubs and the league itself, we have years of experience in navigating
                            this complex landscape and are ready to take that next step with you.
                        </p>
                    </div>
                </div>
            </div>
            <div className={styles.content}>
                <div className={styles.descriptionContainer} style={{ width, maxWidth: width }}>
                    <div className={styles.description}>
                        <div className={styles.descriptionText}>
                            <h2>RECRUIT</h2>
                            <p>
                                <span style={{ fontWeight: 800, color: '#2e9ef2' }}>
                                    Enhance the way you evaluate recruits.{' '}
                                </span>
                                Telemetry Sports Recruit eliminates the guesswork from game film. Leveraging our
                                computer vision technology, validated by NFL-level data, we deliver player metrics such
                                as speed, acceleration, and projected 40-yard dash times—all directly from the tape.
                            </p>
                            <p>
                                <span style={{ fontWeight: 800, color: '#2e9ef2' }}>Data is everything. </span>
                                Using the same technology as NFL scouts, Telemetry Sports Recruit makes identifying
                                player athleticism efficient and effective, helping you minimize misses and optimize
                                roster decisions.
                            </p>
                        </div>
                        <div className={styles.graphic}>
                            <VideoComponent
                                url="https://s3.amazonaws.com/scratch.telemetry.fm/ar/telemetry-website/videos/ts-recruit.mp4"
                                styleClass={styles.video}
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.pillars}>
                    <div>
                        <h2>experienced.</h2>
                        <p>
                            Trusted as a responsive sports data technology provider to over 45 NFL and NCAA&reg;
                            organizations, Telemetry Sports offers scouts a reliable recruitment tool to aid your
                            process of evaluating prospective player personnel.
                        </p>
                    </div>
                    <div>
                        <h2>customizable.</h2>
                        <p>
                            At Telemetry Sports, we understand a team&apos;s unique needs and desires when evaluating
                            and organizing potential recruits. Create custom lists based on your roster needs and use
                            Telemetry&apos;s on-field performance metrics to give every player a measurable value.
                        </p>
                    </div>
                    <div>
                        <h2>streamlined.</h2>
                        <p>
                            Beyond the perks of using Telemetry Sports&apos; efficient and reliable recruitment tool for
                            individual team scouting personnel, the ability to customize how data is shared, curated,
                            and delivered is dependent on each team&apos;s individual needs.
                        </p>
                    </div>
                </div>
            </div>
            <ContactUs />
        </div>
    );
};

export default Recruit;
