import React, { FunctionComponent } from 'react';
import { Button, Image } from 'semantic-ui-react';
import webStyles from './afca.module.scss';
import mobileStyles from './afca-mobile.module.scss';
import useMobile from 'src/shared/hooks/use-mobile';
import coaching from '../../assets/images/verticals/coaching.svg';
import recruiting from '../../assets/images/verticals/recruiting.svg';
import header from '../../assets/images/textures/path-texture-large.svg';
import { Helmet } from 'react-helmet';

import ReactGA from 'react-ga4';

const AfcaLandingPage: FunctionComponent = () => {
    const mobile = useMobile();
    const styles = mobile ? mobileStyles : webStyles;

    const recordEvent = (vertical: 'pro' | 'college' | 'recruit' | 'broadcast') => {
        ReactGA.event({
            category: 'Clicks',
            action: 'Vertical_Click',
            label: vertical,
        });
    };

    return (
        <div className={styles.body}>
            <div className={styles.header}>
                <div className={styles.afcaHeader}>
                    <h1>
                        Visit us at AFCA Booth <span className={styles.afcaBoothNumber}>412</span>!
                    </h1>
                </div>
                <div className={styles.graphicHeader}>WHO WE ARE</div>
                <div>
                    <p>
                        Telemetry Sports is a trusted advisor to over 45 NFL and NCAA&reg; teams, using player tracking
                        data to deliver quality sports data and technology that produces efficient and winning results.
                        Known for swift and reliable solutions—Telemetry Sports offers organizations hands-on support
                        built with their unique needs in mind.
                    </p>
                    <p>
                        We&apos;re a custom shop at heart. We understand your team has individual needs and goals.
                        Telemetry Sports&apos; one-stop-shop for quality sports technology works as an essential
                        extension of your processes allowing more time for informed decision-making, practice planning,
                        planning, play and player performance evaluation, recruitment, and overall game preparation.
                    </p>
                </div>
                <Image src={header} />
            </div>
            <div className={styles.collegeRecruitDiv}>
                <div className={styles.collegeRecruitContent}>
                    <div className={styles.collegeRecruitContainers}>
                        <Image src={coaching} style={{ width: '70%' }} />
                        <div className={styles.listStyling}>
                            <ul>
                                <li>Automated Self and Opponent Scouting Reports</li>
                                <li>Your Data Integrated into our Product for Tendencies and Analysis</li>
                                <li>Customized Playcards Based on Actual Player Tracking Data and Location</li>
                            </ul>
                        </div>
                        <div className={styles.learnStyling}>
                            <a
                                href="/college"
                                target="_blank"
                                style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
                            >
                                <Button className={styles.learnBtn} onClick={() => recordEvent('college')}>
                                    Learn More
                                </Button>
                            </a>
                        </div>
                    </div>
                    <div className={styles.collegeRecruitContainers}>
                        <Image src={recruiting} style={{ width: '70%' }} />
                        <div className={styles.listStyling}>
                            <ul>
                                <li>Live Transfer Portal filtering and Custom Boards</li>
                                <li>In-game Speeds and Accelerations on your Recruits</li>
                                <li>Roster Management Salary Cap Tool</li>
                            </ul>
                        </div>
                        <div className={styles.learnStyling}>
                            <a
                                href="/recruit"
                                target="_blank"
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignContent: 'flex-end',
                                }}
                            >
                                <Button className={styles.learnBtn} onClick={() => recordEvent('recruit')}>
                                    Learn More
                                </Button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            {!mobile ? (
                <>
                    <div className={styles.jotformContainer}>
                        <div className={styles.jotformDiv}>
                            <div>
                                <h2>Want to see what we&apos;re talking about?</h2>
                                <h2> Click the links below to get a complimentary scouting report or player speed!</h2>
                                <div className={styles.submitDiv}>
                                    <a href="https://form.jotform.com/233514612142041" target="_blank" rel="noreferrer">
                                        <div className={styles.buttonContainer}>
                                            <Button className={styles.submitPlayer} fluid>
                                                Get Report
                                            </Button>
                                        </div>
                                    </a>
                                    <a href="https://form.jotform.com/232184386373057" target="_blank" rel="noreferrer">
                                        <div className={styles.buttonContainer}>
                                            <Button className={styles.submitPlayer} fluid>
                                                Get Speeds
                                            </Button>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className={styles.jotformContent}>
                        <div className={styles.jotformContainer}>
                            <div className={styles.jotformDiv}>
                                <div style={{ width: '100%' }}>
                                    <h1>Try it Out!</h1>
                                    <h2> Get a free scouting report or player speed</h2>
                                    <div className={styles.submitDiv}>
                                        <a
                                            href="https://form.jotform.com/233514612142041"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <Button className={styles.submitPlayer} fluid>
                                                Get Report
                                            </Button>
                                        </a>
                                        <a
                                            href="https://form.jotform.com/232184386373057"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <Button className={styles.submitPlayer} fluid>
                                                Get Speeds
                                            </Button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            <Helmet>
                <script type="text/javascript">
                    {`
                    ttd_dom_ready( function() {
                        if (typeof TTDUniversalPixelApi === 'function') {
                            var universalPixelApi = new TTDUniversalPixelApi();
                            universalPixelApi.init("9xxrvie", ["oay9j2l"], "https://insight.adsrvr.org/track/up"
                            );
                        }
                    });
                    `}
                </script>
            </Helmet>
        </div>
    );
};

export default AfcaLandingPage;
