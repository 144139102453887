import React, { FunctionComponent } from 'react';
import { Image } from 'semantic-ui-react';
import ContactUs from 'src/shared/components/contact/contact.component';
import webStyles from './pro.module.scss';
import mobileStyles from './pro-mobile.module.scss';
import useMobile from 'src/shared/hooks/use-mobile';
import useWindowDimensions from 'src/shared/hooks/use-window-dimensions';
import footballDive from 'src/assets/images/players/football-dive.svg';
import VideoComponent from 'src/shared/components/videoComponent/videoComponent';
import proVertical from '../../assets/images/verticals/pro.svg';
import particles from '../../assets/images/textures/particles.svg';
import api from '../../assets/images/icons/api-icon.svg';

const Pro: FunctionComponent = () => {
    const mobile = useMobile();
    const styles = mobile ? mobileStyles : webStyles;

    const { width } = useWindowDimensions();

    return (
        <div className={styles.container}>
            <div className={styles.headline}>
                <div className={styles.textContainer}>
                    <h2>
                        Join the <span className={styles.clientSpan}>25+ NFL</span> partners and multiple Super Bowl{' '}
                        <span className={styles.underlineSpanTop}>
                            champions
                            <span className={styles.underlineSpan} />
                        </span>{' '}
                        who trust us.
                    </h2>
                    {!mobile && <Image src={footballDive} className={styles.backgroundImage} />}
                </div>
                <Image src={particles} className={styles.particles} />
            </div>
            <div className={styles.content}>
                <div className={styles.proContentContainer} style={{ width, maxWidth: width }}>
                    <div className={styles.proContent}>
                        <div className={styles.proDescription}>
                            <Image src={proVertical} style={{ height: '53px', marginBottom: '30px' }} />
                            {mobile && (
                                <div className={styles.proVideoContainer}>
                                    <VideoComponent
                                        url="https://s3.amazonaws.com/scratch.telemetry.fm/ar/telemetry-website/videos/ts-pro.mp4"
                                        styleClass={styles.video}
                                    />
                                </div>
                            )}
                            <p>
                                <span style={{ fontWeight: 800, color: '#2e9ef2' }}>Built just for you.</span> At
                                Telemetry Sports, we&apos;re good listeners. We hear our pro clients when they share
                                their unique needs. Our Pro product translates those needs into opportunities to
                                streamline efficiencies to maximize the ability to view data reliably and quickly. Our
                                ethical treatment of shared data technology seamlessly integrates into a custom-built
                                product offering teams customized solutions for reporting, evaluation, tracking, and
                                film.
                            </p>
                            <p>
                                <span style={{ fontWeight: 800, color: '#2e9ef2' }}>
                                    We&apos;re quick and reliable.{' '}
                                </span>
                                Trusted by over 25 NFL organizations, Telemetry Sports Pro is your search engine for all
                                things football. Search and organize large amounts of curated data with over 900 unique
                                tags to streamline the data gathering process.
                            </p>
                        </div>
                        {!mobile && (
                            <div className={styles.proVideoContainer}>
                                <VideoComponent
                                    url="https://s3.amazonaws.com/scratch.telemetry.fm/ar/telemetry-website/videos/ts-pro.mp4"
                                    styleClass={styles.video}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className={styles.pillars}>
                <div className={styles.pillarText}>
                    <div>
                        <h2>flexible.</h2>
                        <p>
                            Every collaboration Telemetry Sports undertakes starts with reliability and the ability to
                            create customized data technology solutions and provide those solutions for professional
                            organizations under their own unique team banner. These solutions are built to be flexible
                            and respond to individual staff needs organization wide.
                        </p>
                    </div>
                    <div>
                        <h2>unique.</h2>
                        <p>
                            We&apos;re listening. Telemetry Sports transitions the active-listening phase with clients
                            into production of on-demand, time-saving customized data technology solutions that will
                            allow professional organizations to stay informed, proactive decision-makers.
                        </p>
                    </div>

                    <div>
                        <h2>successful.</h2>
                        <p>
                            We&apos;re partners for the long haul. Telemetry Sports is intent on supporting professional
                            organizational partners to aspire to and reach their own successes. The win-loss columns at
                            the end of the season tell a story but not the whole story. Telemetry Sports&apos; data
                            technology products can offer your organization the ability to get ahead in the game and
                            make team goals and winning aspirations reality.
                        </p>
                    </div>
                </div>
            </div>
            <div className={styles.apiContainer} style={{ width, maxWidth: width }}>
                <div className={styles.apiDescription}>
                    <div className={styles.apiDescriptionText}>
                        <h2>
                            TELEMETRY <span className={styles.apiSpan}>API</span>
                        </h2>
                        {mobile && (
                            <div className={styles.apiGraphic}>
                                <Image src={api} />
                            </div>
                        )}
                        <p>
                            <span style={{ fontWeight: 800, color: '#2e9ef2' }}>A New Way to Use Your Data.</span>{' '}
                            Access the data you need without delay. Thanks to our streamlined and seamless integration
                            capabilities, our API is designed for flexibility. Our solution effortlessly adapts to your
                            existing systems, enabling you to stay focused on what matters most.
                        </p>
                        <p>
                            <span style={{ fontWeight: 800, color: '#2e9ef2' }}>Customized to Your Data Needs </span>
                            Experience the ease of custom endpoints designed specifically for your needs, backed by our
                            reliable on-call support team. Our intuitive, user-friendly platform streamlines even the
                            most challenging tasks, making your workflow effortless.
                        </p>
                    </div>
                    {!mobile && (
                        <div className={styles.apiGraphic}>
                            <Image src={api} />
                        </div>
                    )}
                </div>
            </div>
            <ContactUs />
        </div>
    );
};

export default Pro;
