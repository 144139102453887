import React, { FunctionComponent, useCallback, useState } from 'react';
import { slide as Menu } from 'react-burger-menu';
import { useRoutes } from 'react-router-dom';
import { Button, Icon } from 'semantic-ui-react';
import NAVIGATION_ROUTES, { pathToName } from 'src/config/routes';
import IRoute from 'src/shared/models/interfaces/iroute';
import Logo from '../client-logo/client-logo.component';
import ReactGA from 'react-ga4';

import styles from './mobile-menu.module.scss';

const MobileMenu: FunctionComponent<{ light: boolean }> = ({ light }) => {
    const [openSubmenu, setOpenSubmenu] = useState<string | null>(null);

    const route = useRoutes(NAVIGATION_ROUTES);

    const active = route?.props.match.route.path;
    const activeSubitem = window.location.pathname;

    const renderNavItems = useCallback(() => {
        const navItems = NAVIGATION_ROUTES.filter((r: IRoute) => r.isNavItem && !r.isWebOnly);
        return (
            navItems &&
            navItems.map((n: IRoute, index: number) => {
                if (n.children) {
                    return (
                        <div key={index} className="menu-item-wrapper">
                            <div
                                className="menu-item parent"
                                onClick={() => setOpenSubmenu(openSubmenu === n.path ? null : n.path)}
                                style={{ color: n.path === active ? '#2f9ff5' : 'whitesmoke' }}
                            >
                                {n.name}
                                <Icon
                                    name="caret down"
                                    style={{
                                        marginLeft: '0.5rem',
                                        transform: openSubmenu === n.path ? 'rotate(180deg)' : 'none',
                                        transition: 'transform 0.2s ease',
                                    }}
                                />
                            </div>
                            <div className="submenu" style={{ display: openSubmenu === n.path ? 'block' : 'none' }}>
                                {n.children.map((child, childIndex) => (
                                    <a
                                        key={`${index}-${childIndex}`}
                                        className="menu-subitem child"
                                        href={`/${n.path}/${child.path}`}
                                        style={{
                                            color:
                                                `/${n.path}/${child.path}` === activeSubitem ? '#2f9ff5' : 'whitesmoke',
                                            paddingLeft: '2rem',
                                        }}
                                    >
                                        {pathToName[`/${n.path}/${child.path}`]}
                                    </a>
                                ))}
                            </div>
                        </div>
                    );
                }
                return (
                    <a
                        key={index}
                        id={n.path}
                        className="menu-item"
                        href={`/${n.path}`}
                        style={{ color: n.path === active ? '#2f9ff5' : 'whitesmoke' }}
                    >
                        {n.name}
                    </a>
                );
            })
        );
    }, [active, openSubmenu]);

    const recordEvent = () => {
        ReactGA.event({
            category: 'Clicks',
            action: 'Contact',
            label: 'header',
        });
    };

    return (
        <div className={!light ? styles.navContainer : styles.navContainerLight}>
            <Logo color={!light ? 'white' : 'full'} />
            <Menu right burgerBarClassName={light ? 'bm-burger-bars-light' : 'bm-burger-bars'}>
                <a
                    key="home"
                    id="home"
                    className="menu-item"
                    href="/"
                    style={{ color: '' === active ? '#2f9ff5' : 'whitesmoke' }}
                >
                    Home
                </a>
                {renderNavItems()}
                <a key="contact" id="contact" href="/contact" className={styles.contactParent}>
                    <Button className={styles.contact} onClick={recordEvent}>
                        Contact Us
                    </Button>
                </a>
            </Menu>
        </div>
    );
};

export default MobileMenu;
