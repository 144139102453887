import React, { FunctionComponent } from 'react';
import { Link, useMatch, useNavigate, useResolvedPath } from 'react-router-dom';
import { Dropdown, Menu } from 'semantic-ui-react';
import IRoute from '../../../shared/models/interfaces/iroute';
import { pathToName } from 'src/config/routes';

interface NavigationItemProps {
    route: IRoute;
    light: boolean;
}

const NavigationItem: FunctionComponent<NavigationItemProps> = (props) => {
    const { route, light } = props;
    const resolved = useResolvedPath(route.path ?? '');
    const match = useMatch({ path: resolved.pathname, end: false });
    const navigate = useNavigate();
    const handleItemClick = () => {
        if (!route.path) return;
        navigate(route.path.replace('/*', ''), { replace: false });
    };

    if (route.children) {
        const dropDownMatch = match?.pathname.includes(route.path);
        return (
            <Dropdown item secondary text={route.name} className={`nav-item ${dropDownMatch ? 'active' : ''}`} as="a">
                <Dropdown.Menu className="nav-dropdown-menu">
                    {route.children.map((child, index) => {
                        const childResolved = useResolvedPath(`/${route.path}/${child.path}`);
                        const childMatch = useMatch({ path: childResolved.pathname, end: true });
                        return (
                            <Dropdown.Item
                                key={index}
                                as={Link}
                                to={`/${route.path}/${child.path}`}
                                className={`nav-dropdown-item ${childMatch ? 'active' : ''}`}
                            >
                                <span className={`nav-dropdown-item ${childMatch ? 'active' : ''}`}>
                                    {pathToName[`/${route.path}/${child.path}`]}
                                </span>
                            </Dropdown.Item>
                        );
                    })}
                </Dropdown.Menu>
            </Dropdown>
        );
    }

    return (
        <Menu.Item
            active={match !== null}
            as="a"
            className="nav-item"
            onClick={handleItemClick}
            style={{ color: light ? 'blue' : 'white' }}
        >
            {route.name}
        </Menu.Item>
    );
};

export default NavigationItem;
